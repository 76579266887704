import React, { memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { 
    Box, 
    Typography,
    Button,
    Grid
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Element, scroller } from 'react-scroll';
import BlogCardItem from 'components/BlogCardItem';
import { colors } from 'themes/styles';
import Lottie from 'react-lottie';
import intro_home from '../../assets/lotties/intro_home.json';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <Box>
            <Element name="home">
                <Box sx={{ padding: '3%', display: 'flex', alignItems: 'center', paddingBottom: '40px' }}>
                    <Box>
                        <Typography sx={{ fontSize: { xs: '40px', md: '6.55vw' }, fontWeight: '600' }}>
                        {t('systemIntroduce')}
                        </Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '400', marginY: '16px' }}>
                        {t('slogan')}
                        </Typography>
                        <Button onClick={() => window.open('https://www.facebook.com/profile.php?id=61561188883433', '_blank')} 
                        variant="contained" 
                        sx={{ backgroundColor: colors.mainPrimaryColor, marginY: '14px', paddingX: '52px', paddingY: '12px', borderRadius: 5, }}>
                            <Typography sx={{ fontSize: '18px', fontWeight: '200' }}>
                            Fanpage
                            </Typography>
                        </Button>
                    </Box>
                    <Box sx={{ 
                        display: { xs: 'none', sm: 'block' }, 
                        width: '2000px'
                        }}>
                        <Lottie 
                        options={ { animationData: intro_home } } 
                        style={{ width:  "100%" }}
                        />
                        {/* <img 
                        alt={'abc'}
                        style={{ objectFit: 'contain', }}
                        width={"100%"}
                        src={require('../../assets/images/intro_home.png')}/> */}
                    </Box>
                </Box>
            </Element>
            <Element name="news">
                <Box 
                    sx={{ 
                        padding: '3%', 
                        paddingY: '50px', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        textAlign: 'center' 
                    }}
                    >
                    <Typography 
                        sx={{ 
                        paddingBottom: '32px', 
                        color: "#BF3A2B", 
                        fontSize: '18px', 
                        fontWeight: '600', 
                        textAlign: 'center' 
                        }}
                    >   
                        {t('news')}
                    </Typography>
                    <Grid 
                        container 
                        spacing={2} 
                        sx={{ 
                        display: 'flex',
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        textAlign: 'center'  
                        }}
                    >
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        
                        
                        
                    </Grid>
                </Box>
            </Element>
            <Element name="products">
                <Box sx={{ padding: '3%', backgroundColor: 'white', paddingY: '50px' }}>
                    <Typography sx={{ color: "#BF3A2B", fontSize: '18px', fontWeight: '600', textAlign: 'center' }}>
                    Our Games
                    </Typography>
                </Box>
            </Element>
            <Box id="about" sx={{ padding: '3%', paddingY: '50px' }}>
                <Typography sx={{ color: "#BF3A2B", fontSize: '18px', fontWeight: '600', textAlign: 'center' }}>
                    About Us
                </Typography>
                <Typography sx={{ paddingBottom: '20px', fontSize:{ xs: '38px', md: '48px' }, fontWeight: '600', textAlign: 'center' }}>
                    Explore Our Expertise
                </Typography>
                <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img 
                            alt={'Game Development'}
                            style={{ objectFit: 'contain' }}
                            width={"100px"}
                            src={require('../../assets/images/gallery.png')}
                        />
                        <Typography sx={{ fontSize: '20px', fontWeight: 600, paddingTop: '12px' }}>
                            Game development
                        </Typography>
                        <Typography sx={{ fontSize: '18px', maxWidth: '300px', textAlign: 'center' }}>
                            We always strive to create quality products for gamers
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img 
                            alt={'Game Development'}
                            style={{ objectFit: 'contain' }}
                            width={"100px"}
                            src={require('../../assets/images/gallery.png')}
                        />
                        <Typography sx={{ fontSize: '18px', fontWeight: 600, paddingTop: '12px' }}>
                            Game development
                        </Typography>
                        <Typography sx={{ fontSize: '18px', maxWidth: '300px', textAlign: 'center' }}>
                            We always strive to create quality products for users
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Element name="team">
                <Box sx={{ padding: '3%', backgroundColor: 'white', paddingY: '50px' }}>
                    <Typography sx={{ color: "#BF3A2B", fontSize: '18px', fontWeight: '600', textAlign: 'center' }}>
                    Our Team
                    </Typography>
                </Box>
            </Element>
            

        </Box>
    );
}


export default memo(Home, isEqual);