import React, { memo } from 'react';
import isEqual from 'react-fast-compare';
import { Box, Typography, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import MainCommunity from './components/main.community';

const CommunityPage = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box padding={isSmallScreen ? 2 : 4}>
            <Grid container>
                {/* Left Sidebar */}
                {isMediumOrLarger && (
                    <Grid item xs={12} md={2} lg={2}>
                        <Box
                            sx={{
                                backgroundColor: '#f5f5f5',
                                padding: 2,
                                height: '100px',
                                border: '1px solid #ddd',
                                position: 'sticky',
                                top: 0, // Giữ sidebar ở trên cùng khi cuộn
                            }}
                        >
                            <Typography variant="h6">Left Sidebar</Typography>
                            {/* Additional sidebar content */}
                        </Box>
                    </Grid>
                )}

                {/* Main Content */}
                <Grid item xs={12} md={isMediumOrLarger ? 8 : 12} lg={8}>
                    <Box
                        sx={{
                            backgroundColor: '#ffffff',
                            padding: isSmallScreen ? 1 : 2,
                            maxWidth: isMediumOrLarger ? '66%' : '100%',
                            margin: '0 auto',
                            //border: '1px solid #ddd',
                            overflowY: 'auto', // Đảm bảo phần tử cuộn được
                        }}
                    >
                        <MainCommunity />
                    </Box>
                </Grid>

                {/* Right Sidebar */}
                {isMediumOrLarger && (
                    <Grid item xs={12} md={2} lg={2}>
                        <Box
                            sx={{
                                backgroundColor: '#f5f5f5',
                                padding: 2,
                                height: '100vh',
                                border: '1px solid #ddd',
                                position: 'sticky',
                                top: 0, // Giữ sidebar ở trên cùng khi cuộn
                            }}
                        >
                            <Typography variant="h6">Banner Quảng Cáo</Typography>
                            {/* Additional sidebar content */}
                        </Box>
                    </Grid>
                )}
            </Grid>

        </Box>
    );
};

export default memo(CommunityPage, isEqual);
