import { createBrowserRouter } from 'react-router-dom';
import Layout from 'components/Layout/Layout';
import Home from 'pages/Home';
import ReviewPage from 'pages/ReviewPage';
import ExamPage from 'pages/ExamPage';
import routerName from 'constant/routers';
import LoginPage from 'pages/Auth/login.page';
import RegisterPage from 'pages/Auth/register.page';
import AuthLayout from 'components/AuthLayout';
import ForgotPasswordPage from 'pages/Auth/forgotPassword.page';
import OTPPage from 'pages/Auth/otp.page';
import CommunityPage from 'pages/Community/community.page';
import NewsPage from 'pages/News/news.page';
import NotFoundPage from 'pages/NotFound/notFound.page';
export const routers = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        path: '/',
        element: <Layout />,
        children: [
          {
            path: routerName.home,
            element: (
              <Home />
            ),
          },
          {
            path: routerName.review,
            element: (
              <ReviewPage />
            ),
          },
          {
            path: routerName.exam,
            element: (
              <ExamPage />
            ),
          },
          {
            path: routerName.community,
            element: (
              <CommunityPage />
            ),
          },
          {
            path: routerName.news,
            element: (
              <NewsPage />
            ),
          },
          
        ]  
      },

      //auth
      {
        path: '/',
        element: <AuthLayout />,
        children: [
          {
            path: routerName.login,
            element: (
              <LoginPage />
            ),
          },
          {
            path: routerName.register,
            element: (
              <RegisterPage />
            ),
          },
          {
            path: routerName.forgotPassword,
            element: (
              <ForgotPasswordPage />
            ),
          },
          {
            path: routerName.otp,
            element: (
              <OTPPage />
            ),
          },
        ]  
      },

      
    ]  
  },
  { path: '*', element: <NotFoundPage /> },
]);
