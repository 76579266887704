import React, { memo, useState, useEffect } from 'react';
import { List, ListItem, CircularProgress } from '@mui/material';
import { Element } from 'react-scroll';
import PostItem from './item.post';
import PostContentComponent from './postContent.component';

const ContentList: React.FC = () => {
    const [items, setItems] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        loadMoreItems(); // Load initial items
        window.addEventListener('scroll', handleScroll); // Attach scroll listener

        return () => {
            window.removeEventListener('scroll', handleScroll); // Cleanup listener
        };
    }, []);

    const loadMoreItems = () => {
        if (isLoading || !hasMore) return;

        setIsLoading(true);

        // Simulate an API call with timeout
        setTimeout(() => {
            setItems((prevItems) => {
                const newItems = Array.from({ length: 10 }, (_, i) => `Item ${prevItems.length + i + 1}`);
                const updatedItems = [...prevItems, ...newItems];

                if (updatedItems.length >= 50) {
                    setHasMore(false); // Stop loading after 50 items
                }

                return updatedItems;
            });
            setIsLoading(false);
        }, 1000);
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const clientHeight = window.innerHeight || document.documentElement.clientHeight;
        const scrollHeight = document.documentElement.scrollHeight;

        // Check if near bottom
        if (scrollHeight - scrollTop <= clientHeight + 1 && hasMore && !isLoading) {
            loadMoreItems();
        }
    };

    return (
        <List>
            <PostContentComponent />
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    <PostItem />
                </React.Fragment>
            ))}
            {isLoading && (
                <Element name="loadingSpinner">
                    <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </ListItem>
                </Element>
            )}
        </List>
    );
};

export default memo(ContentList);
