import React, { memo, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { 
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
    Button,
    useMediaQuery, 
    Menu,
    MenuItem,
    Avatar,
    ListItemIcon,
    Tooltip
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import routerName from 'constant/routers';
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import LogoImage from 'components/LogoImage';
import { colors } from 'themes/styles';
import { useTranslation } from 'react-i18next';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;


function Header(props: Props) {
    const { window } = props;
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const NAV_ITEMS = [
        {
            label: t('homePage'),
            route: routerName.home,
        },
        {
            label: t('reviewPage'),
            route: routerName.review
        },
        {
            label: t('community'),
            route: routerName.community
        },
        {
            label: t('news'),
            route: routerName.news
        },
        {
            label: t('exam'),
            route: routerName.exam
        },
        {
            label: t('contact'),
            route: routerName.contact
        },
        //{
        //    label: 'Hiring',
        //    route: '/hiring'
        //},
        
    ];

    const handleDrawerToggle = () => {
        if (!drawerOpen) {
        setMobileOpen((prevState) => !prevState);
        } else {
        setDrawerOpen(false);
        }
    };

    const handleNavToggle = () => {
        if (!mobileOpen) {
        setDrawerOpen((prevState) => !prevState);
        } else {
        setMobileOpen(false);
        }
    };

    
    const onNavigatePage = (route: string) => {
        console.log(route);
        
        navigate(route)
    }

    

  
    const drawer = (
        <Box sx={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <LogoImage sx={{ alignSelf: 'center' }} />

        <Divider />
        <List>
            {NAV_ITEMS.map((item) => (
            <ListItem onClick={() => onNavigatePage(item.route)} key={item.route} disablePadding>
                <ListItemButton sx={{ textAlign: 'center', textDecoration: location.pathname === item.route ? 'underline' : 'none' }} onClick={handleNavToggle}>
                    <ListItemText primary={item.label} />
                </ListItemButton>
            </ListItem>
            ))}
        </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (

        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            
            <AppBar component="nav" sx={{ backgroundColor: '#fff', boxShadow: 'none' }}>
                <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon color='primary' />
                    </IconButton>

                    {/* Center the LogoImage on small screens */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', sm: 'none' },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <LogoImage sx={{ height: '60px', width: '120px' }} />
                    </Box>

                    {/* LogoImage on larger screens */}
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, marginX: '20px' }}>
                        <LogoImage sx={{ height: '60px', width: '120px' }} />
                    </Box>

                    <Box sx={{ flexGrow: 5, display: { xs: 'none', sm: 'flex' } }}>
                        {NAV_ITEMS.map((item) => (
                            <Button key={item.route} onClick={() => onNavigatePage(item.route)}>
                                <Typography
                                    sx={{
                                        fontSize: '22px',
                                        fontWeight: location.pathname === item.route ? 600 : 400,
                                        background: '#2B3D4F',
                                        opacity: location.pathname === item.route ? 1 : 0.75,
                                        '-webkit-background-clip': 'text',
                                        '-webkit-text-fill-color': 'transparent',
                                        textDecoration: location.pathname === item.route ? 'underline' : 'none',
                                    }}
                                >
                                    {item.label}
                                </Typography>
                            </Button>
                        ))}
                    </Box>

                    {/* Move Tooltip with Avatar to the far right on all screen sizes */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={handleClose}>
                                <Avatar /> Profile
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Avatar /> My account
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <PersonAdd fontSize="small" />
                                </ListItemIcon>
                                Add another account
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                Settings
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>


            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>

    );
}

export default memo(Header, isEqual);
