import React, { useCallback } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  useMediaQuery
} from '@mui/material';
import { Facebook, GitHub, Google } from '@mui/icons-material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { colors } from 'themes/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routerName from 'constant/routers';

const LoginPage = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onLogin = useCallback(()=> {
    navigate(routerName.home)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      
        <Container maxWidth="xs">
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: 3,
              borderRadius: 2,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography variant="h5" component="h1" gutterBottom>
              {t('signIn')}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              <IconButton color="primary">
                <Facebook sx={{ color: colors.mainPrimaryColor }}/>
              </IconButton>
              <IconButton color="primary">
                <GitHub sx={{ color: colors.mainPrimaryColor }}/>
              </IconButton>
              <IconButton color="primary">
                <Google sx={{ color: colors.mainPrimaryColor }}/>
              </IconButton>
            </Box>
            <TextField
              margin="normal"
              fullWidth
              label={t("phonenumber")}
              variant="outlined"
            />
            <TextField
              margin="normal"
              fullWidth
              label={t('password')}
              type="password"
              variant="outlined"
            />
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label={t('rememberPassword')}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={onLogin}
              sx={{ mt: 2, backgroundColor: colors.mainPrimaryColor }}
            >
              {t('signIn')}
            </Button>
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
                <Typography variant="body2">
                {t('dontAcc')}{' '}
                <Button
                    variant="text"
                    onClick={() => navigate(routerName.register)}
                    sx={{ padding: 0, minWidth: 'auto' }}
                >
                    {t('signUp')}
                </Button>
                </Typography>
            </Grid>
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
                <Button
                    variant="text"
                    onClick={() => navigate(routerName.forgotPassword)}
                    sx={{ padding: 0, minWidth: 'auto' }}
                >
                    {t('forgotPassword')}
                </Button>
            </Grid>
          </Box>
        </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
