import React from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  useMediaQuery,
  MenuItem
} from '@mui/material';
import { Facebook, GitHub, Google } from '@mui/icons-material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { colors } from 'themes/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routerName from 'constant/routers';

const RegisterPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Options for the "Who are you?" dropdown
  const roles = [
    { value: 'student', label: t('student') },
    { value: 'teacher', label: t('teacher') },
    { value: 'parent', label: t('parent') },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 3,
            borderRadius: 2,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            {t('signUp')}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
            <IconButton color="primary">
              <Facebook sx={{ color: colors.mainPrimaryColor }} />
            </IconButton>
            <IconButton color="primary">
              <GitHub sx={{ color: colors.mainPrimaryColor }} />
            </IconButton>
            <IconButton color="primary">
              <Google sx={{ color: colors.mainPrimaryColor }} />
            </IconButton>
          </Box>
          <TextField
            margin="normal"
            fullWidth
            label={t("phonenumber")}
            variant="outlined"
          />
          <TextField
            margin="normal"
            fullWidth
            label={t("email")}
            variant="outlined"
          />
          <TextField
            margin="normal"
            fullWidth
            label={t("firstName")}
            variant="outlined"
          />
          <TextField
            margin="normal"
            fullWidth
            label={t("lastName")}
            variant="outlined"
          />
          <TextField
            margin="normal"
            fullWidth
            select
            label={t("whoAreYou")}
            variant="outlined"
          >
            {roles.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            fullWidth
            label={`${t('referralCode')} (${t('ifAny')})`}
            variant="outlined"
          />
          <TextField
            margin="normal"
            fullWidth
            label={t('password')}
            type="password"
            variant="outlined"
          />
          <TextField
            margin="normal"
            fullWidth
            label={t('confirmPassword')}
            type="password"
            variant="outlined"
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label={t('agreeTerms')}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2, backgroundColor: colors.mainPrimaryColor }}
          >
            {t('signUp')}
          </Button>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Typography variant="body2">
              {t('alreadyHaveAccount')} {' '}
              <Button variant="text"
                onClick={() => navigate(routerName.login)}
                sx={{ padding: 0, minWidth: 'auto' }} href="#">{t('signIn')}</Button>
            </Typography>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default RegisterPage;
