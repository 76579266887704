import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NProgress from 'nprogress';
import { Box, Toolbar } from '@mui/material';
import Header from 'components/Header';
import Footer from 'components/Footer';
import routerName from 'constant/routers';

function Layout() {
  const location = useLocation();

  useEffect(() => {
    console.log('Current location:', location.pathname);
    NProgress.done();
    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box width="100%">
      <Header/>
      <Toolbar />
      <Box component="main" sx={{ width: '100%', }}>
        <Outlet />
      </Box>
      {location.pathname!==routerName.community && <Footer />}
    </Box>
  );
}

export default Layout;
