import React from 'react';
import { Grid } from '@mui/material';

interface PostImagesProps {
    images: string[]; // Array of image URLs
}

const PostImages: React.FC<PostImagesProps> = ({ images }) => {

    const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = '../../assets/images/default_image.png';
    };

    const renderImages = () => {
        if (images.length === 1) {
            return (
                <Grid item xs={12}>
                    <img
                        src={images[0]}
                        alt=""
                        onError={handleImageError}
                        style={{ objectFit: 'cover', width: '100%', height: '65vh', borderRadius: '8px' }}
                    />
                </Grid>
            );
        } else if (images.length === 2) {
            return images.map((img, index) => (
                <Grid item xs={6} key={index}>
                    <img
                        src={img}
                        alt=""
                        onError={handleImageError}
                        style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                    />
                </Grid>
            ));
        } else if (images.length === 3) {
            return (
                <>
                    <Grid item xs={12}>
                        <img
                            src={images[0]}
                            alt=""
                            onError={handleImageError}
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '50vh',
                                borderRadius: '8px'
                            }}
                        />
                    </Grid>
                    {images.slice(1).map((img, index) => (
                        <Grid item xs={6} key={index}>
                            <img
                                src={img}
                                alt=""
                                onError={handleImageError}
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </Grid>
                    ))}
                </>
            );
        } else {
            return (
                <>
                    <Grid item xs={12}>
                        <img
                            src={images[0]}
                            alt=""
                            onError={handleImageError}
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '50vh',
                                borderRadius: '8px'
                            }}
                        />
                    </Grid>
                    {images.slice(1, 3).map((img, index) => (
                        <Grid item xs={4} key={index}>
                            <img
                                src={img}
                                alt=""
                                onError={handleImageError}
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </Grid>
                    ))}
                    {images.length > 3 && (
                        <Grid item xs={4} style={{ position: 'relative' }}>
                            <img
                                src={images[3]}
                                alt=""
                                onError={handleImageError}
                                style={{ width: '100%', height: 'auto', borderRadius: '8px', opacity: images.length > 4 ? 0.6 : 1 }}
                            />
                            {images.length > 4 && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'white',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        opacity: 1
                                    }}
                                >
                                    +{images.length - 4}
                                </div>
                            )}
                        </Grid>
                    )}
                </>
            );
        }
    };

    return (
        <Grid container spacing={1}>
            {renderImages()}
        </Grid>
    );
};

export default PostImages;
