import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NProgress from 'nprogress';
import { Box, Toolbar } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import backgroundImage from 'assets/images/background_auth.jpg';
function AuthLayout() {
    const theme = useTheme();
    const location = useLocation();

    useEffect(() => {
        //console.log('Current location:', location.pathname);
        NProgress.done();
        return () => {
            NProgress.done();
        };
    }, []);

    return (

        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2
                }}
            >
                <Box component="main" sx={{ width: '100%', }}>
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>




    );
}

export default AuthLayout;
