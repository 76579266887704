import React, { memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { 
    Box,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Lottie from 'react-lottie';
import not_found from '../../assets/lotties/404_not_found.json';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <Box>
            <Lottie 
                options={ { animationData: not_found } } 
                style={{ width: "45%" }}
            />
        </Box>
    );
}


export default memo(NotFoundPage, isEqual);