const routerName = {
    //auth
    login: '/login',
    register: '/register',
    forgotPassword: '/forgot-password',
    otp: '/otp',

    //main
    home: '/',
    exam: '/exam',
    review: '/review',
    news: '/news',
    community: '/community',
    contact: '/contact',
    profile: '/profile',

}

export default routerName;