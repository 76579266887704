export const en = {
    translation: {
        "login": "Login",
        "homePage": "Home",
        "reviewPage": "Review",
        "community": "Community",
        "news": "News",
        "exam": "Exam",
        "contact": 'Contact',
        "systemIntroduce": "System for sharing information and national high school exam questions",
        "slogan": "National high school exam and information sharing system, supporting students to effectively review with diverse and continuously updated documents and exam questions, helping to improve skills and confidently enter important exams . Conquer knowledge every day to confidently achieve your goals in the National High School Exam - success starts with effort today!",
        "signIn": "sign in",
        "signUp": "sign up",
        "dontAcc": "Don't have an account?",
        "phonenumber": "Phone Number",
        "password": "Password",
        "rememberPassword": "Remember password",
        "email": "Email",
        "confirmPassword": "ConFirm password",
        "lastName": "Last name",
        "firstName": "First name",
        "referralCode": "Referral code",
        "ifAny": "if any",
        "student": "Student",
        "teacher": "Teacher",
        "parent": "Parent",
        "whoAreYou": "Who are you?",
        "alreadyHaveAccount": "Already have account?",
        "agreeTerms": "Agree terms",
        "forgotPassword": "Forgot password",
        "confirm": "Confirm",
        "enterPhonetoRetrievePassword": "Enter your phone number to retrieve your password",
        "confirmOTP": "Confirm OTP",
        "enterOTPtoRetrievePassword": "OTP has just been sent to your phone number",
        "doYouHaveExercises": "Do you have any exercises you want to share?",
        
    }
}