import React, { memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { 
    Box, 
    Typography,
    Button,
    Grid
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Element, scroller } from 'react-scroll';
import BlogCardItem from 'components/BlogCardItem';
import LogoImage from 'components/LogoImage';
import { colors } from 'themes/styles';
import Lottie from 'react-lottie';
import intro_home from '../../assets/lotties/intro_home.json';
const ReviewPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <Box>
            <Element name="home">
                <Box sx={{ padding: '3%', display: 'flex', alignItems: 'center', paddingBottom: '40px' }}>
                    Ôn luyện
                </Box>
            </Element>
            
           

        </Box>
    );
}


export default memo(ReviewPage, isEqual);