import React, { useState } from 'react';
import { TextField, Grid } from '@mui/material';

interface OTPInputProps {
  length?: number;
  onChange: (otp: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length = 6, onChange }) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(""));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const value = e.target.value;
    if (!/^[0-9]$/.test(value) && value !== "") return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    onChange(newOtp.join(""));

    if (value && index < length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) (nextInput as HTMLInputElement).focus();
    }
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      {otp.map((data, index) => (
        <Grid item key={index}>
          <TextField
            id={`otp-input-${index}`}
            value={data}
            onChange={(e) => handleChange(e, index)}
            variant="outlined"
            inputProps={{
              maxLength: 1,
              style: {
                width: "2rem",
                height: "2rem",
                textAlign: "center",
                fontSize: "1.2rem",
                padding: 0,
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderWidth: '1.5px',
                  borderColor: 'grey.400',
                },
                '&:hover fieldset': {
                  borderColor: 'primary.light',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
              '& .MuiInputBase-input': {
                padding: 0,
              },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default OTPInput;
