export const vi = {
    translation: {
        "login": "Đăng nhập",
        "homePage": "Trang chủ",
        "reviewPage": "Ôn luyện",
        "community": "Cộng đồng",
        "news": "Tin tức",
        "exam": "Đề thi",
        "contact": "Liên hệ",
        "systemIntroduce": "Hệ thống chia sẻ thông tin, đề thi THPT QG",
        "slogan": "Hệ thống chia sẻ thông tin và đề thi THPT Quốc gia, hỗ trợ học sinh ôn luyện hiệu quả với các tài liệu, đề thi đa dạng và cập nhật liên tục, giúp nâng cao kỹ năng và tự tin bước vào kỳ thi quan trọng. Hãy chinh phục kiến thức mỗi ngày để tự tin đạt được mục tiêu của mình trong kỳ thi THPT Quốc gia - thành công bắt đầu từ sự cố gắng hôm nay!",
        "signIn": "Đăng nhập",
        "signUp": "Đăng ký",
        "dontAcc": "Bạn chưa có tài khoản?",
        "phonenumber": "Số điện thoại",
        "password": "Mật khẩu",
        "rememberPassword": "Nhớ mật khẩu",
        "email": "Email",
        "confirmPassword": "Xác nhận mật khẩu",
        "lastName": "Họ",
        "firstName": "Tên",
        "referralCode": "Mã giới thiệu",
        "ifAny": "Nếu có",
        "student": "Học sinh",
        "teacher": "Giáo viên",
        "parent": "Phụ huynh",
        "whoAreYou": "Bạn là ai?",
        "alreadyHaveAccount": "Bạn đã có tài khoản?",
        "agreeTerms": "Đồng ý điều khoản của chúng tôi",
        "forgotPassword": "Quên mật khẩu",
        "confirm": "Xác nhận",
        "enterPhonetoRetrievePassword": "Nhập số điện thoại của bạn để lấy lại mật khẩu",
        "confirmOTP": "Xác nhận OTP",
        "enterOTPtoRetrievePassword": "OTP vừa được gửi về số điện thoại của bạn",
        "doYouHaveExercises": "Bạn có bài tập nào muốn chia sẻ?",

    }
}