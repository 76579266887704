import React, { memo } from 'react';
import isEqual from 'react-fast-compare';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'white',
        paddingX: '5vw',
        paddingY: '2vw',
        marginTop: '40px',
        borderRadius: '20px',
        margin: '20px',
                            borderWidth: '0px',
                            borderStyle: 'solid',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Box>
            Logo
            <Typography sx={{ color: '#3B3B3B', fontSize: '18px' }}>
              Để lại thông tin và chúng tôi sẽ liên hệ với bạn
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
            <Typography sx={{ color: '#3B3B3B', fontSize: '18px' }}>
            Với đội ngũ nhân viên giàu kinh nghiệm và am hiểu sâu về phát triển Game, chúng tôi tự tin sẽ đem lại những sản phẩm tốt nhất cho người chơi
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                <Grid item xs={12} md={4}>
                <Box>
                    <Typography sx={{ color: '#3B3B3B', fontSize: '18px' }}>
                    Liên hệ chúng tôi
                    </Typography>
                    <Typography sx={{ color: '#3B3B3B', fontSize: '18px' }}>
                    Contact@gmail.com
                    </Typography>
                    <Typography sx={{ color: '#3B3B3B', fontSize: '18px' }}>
                    0374934034
                    </Typography>
                </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                <Box>
                <Typography sx={{ color: '#3B3B3B', fontSize: '18px' }}>
                    Địa chỉ
                    </Typography>
                    <Typography sx={{ color: '#3B3B3B', fontSize: '18px' }}>
                    Tòa nhà Sông Đà 9, Số 1 Nguyễn Hoàng, Mỹ Đình, Nam Từ Liêm, Hà Nội
                    </Typography>
                    
                </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                <Box>
                    <Typography sx={{ color: '#3B3B3B', fontSize: '18px' }}>
                    Liên kết
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                    <IconButton >
                        <FacebookIcon sx={{ color: '#337FFF' }} color='action' />
                    </IconButton>
                    <IconButton >
                        <LinkedInIcon sx={{ color: '#006699' }} color='action' />
                    </IconButton>
                    <IconButton >
                        <TwitterIcon sx={{ color: '#33CCFF' }} color='action' />
                    </IconButton>
                    
                    </Box>
                    
                </Box>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(Footer, isEqual);
