import isEqual from 'react-fast-compare';
import React, { memo, useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

interface LogoImageProps {
    sx?: SxProps<Theme> | undefined
}
const LogoImage = (props: LogoImageProps) => {
    return (
        <Box sx={{ height: '50px', width: '100px', ...props.sx }}>
            <img 
                alt={'abc'}
                style={{ objectFit: 'contain', }}
                width={"100%"}
                src={require('../../assets/images/logo.png')}/>
        </Box>
    )
}

export default memo(LogoImage, isEqual);
