import React, { memo } from 'react';
import isEqual from 'react-fast-compare';
import { Box, Typography, Avatar, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { colors } from 'themes/styles';

const PostContentComponent = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 
    const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md')); 

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: "row", 
            width: '100%',
            alignItems: 'center',
            padding: isSmallScreen ? 1 : 2,
            
        }}>
            <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 56, height: 56, marginRight: 2 }}
            />
            
            <Button sx={{ 
                backgroundColor: colors.white, 
                borderRadius: '100px',
                borderColor: colors.mainPrimaryColor,
                borderWidth: '1px',
                borderStyle: 'solid',
                paddingX: '30px',
                paddingY: '20px', 
                width: '100%', 
            }}>
                <Typography sx={{ width: '100%', textAlign: 'left', color: colors.darkText }}>
                    {t('doYouHaveExercises')}
                </Typography>
            </Button>
            
        </Box>
    );
};

export default memo(PostContentComponent, isEqual);
