import React, { memo } from 'react';
import isEqual from 'react-fast-compare';
import { Box, Typography, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { colors } from 'themes/styles';
import PostContentComponent from './postContent.component';
import ContentList from './list.content';

const MainCommunity = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 
    const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md')); 

    return (
        <Box>
            
            <ContentList />
        </Box>
    );
};

export default memo(MainCommunity, isEqual);
