import React, { useCallback } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  useMediaQuery
} from '@mui/material';
import { Facebook, GitHub, Google } from '@mui/icons-material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { colors } from 'themes/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routerName from 'constant/routers';
import OTPInput from './components/otp.input';
const OTPPage = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onConfirm = useCallback(()=> {
    navigate(routerName.otp)
  }, []);

  const onChangeOTP = useCallback(()=> {

  }, []);

  return (
    <ThemeProvider theme={theme}>
      
        <Container maxWidth="xs">
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: 3,
              borderRadius: 2,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography variant="h5" component="h1" gutterBottom>
              {t('confirmOTP')}
            </Typography>
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
                <Typography variant="body2">
                {t('enterOTPtoRetrievePassword')}
                </Typography>
            </Grid>
            <OTPInput length={6} onChange={onChangeOTP} />

            <Button
              fullWidth
              variant="contained"
              onClick={onConfirm}
              sx={{ mt: 2, backgroundColor: colors.mainPrimaryColor }}
            >
              {t('confirm')}
            </Button>
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
                <Button
                    variant="text"
                    onClick={() => navigate(routerName.login)}
                    sx={{ padding: 0, minWidth: 'auto' }}
                >
                    {t('signIn')}
                </Button>
            </Grid>
          </Box>
        </Container>
    </ThemeProvider>
  );
};

export default OTPPage;
