import React, { memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { 
    Box, 
    Typography,
    Button,
    Grid
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Element, scroller } from 'react-scroll';
import BlogCardItem from 'components/BlogCardItem';
import { colors } from 'themes/styles';
import Lottie from 'react-lottie';
import intro_home from '../../assets/lotties/intro_home.json';
import { useTranslation } from 'react-i18next';

const NewsPage = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <Box>
            <Element name="news">
                <Box 
                    sx={{ 
                        padding: '3%', 
                        paddingY: '50px', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        textAlign: 'center' 
                    }}
                    >
                    <Typography 
                        sx={{ 
                        paddingBottom: '32px', 
                        color: "#BF3A2B", 
                        fontSize: '18px', 
                        fontWeight: '600', 
                        textAlign: 'center' 
                        }}
                    >   
                        {t('news')}
                    </Typography>
                    <Grid 
                        container 
                        spacing={2} 
                        sx={{ 
                        display: 'flex',
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        textAlign: 'center'  
                        }}
                    >
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <BlogCardItem />
                        </Grid>
                        
                        
                        
                    </Grid>
                </Box>
            </Element>
            

        </Box>
    );
}


export default memo(NewsPage, isEqual);